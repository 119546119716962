import React from "react"
import Layout from "../../../Components/pageComponents/contentContainer"
import SquareCard from "../../../Components/pageComponents/squareCard2"
import { Container } from "reactstrap"

export const query = graphql`
    query {
        banner: file(relativePath: { eq: "products/physical-security/Physical Security.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        biometric: file(relativePath: { eq: "products/physical-security/Biometrics Stock.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        cctv: file(relativePath: { eq: "products/physical-security/CCTV and Visual Equipment Stock.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        door: file(relativePath: { eq: "products/physical-security/Door Access Control Stock.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        rfid: file(relativePath: { eq: "products/physical-security/RFID Stock.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        time: file(relativePath: { eq: "products/physical-security/Time and Attendance Stock.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        turnstiles: file(relativePath: { eq: "products/physical-security/Turnstiles Stock.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

export default ({ data }) => {
    return (
        <Layout title="Physical Security" image={data.banner.childImageSharp.fluid}>
            <h1 className="mx-auto my-3 heading1 p-3 text-center">
                <span>Products We Offer</span>
            </h1>
            <Container className="d-flex flex-wrap justify-content-center py-3">
                <SquareCard img={data.biometric.childImageSharp.fluid}
                    title="Biometrics"/>
                <SquareCard img={data.cctv.childImageSharp.fluid}
                    title="CCTV and Visual Equipment">
                    <ul>
                        <li>Analog</li>
                        <li>IP</li>
                    </ul>
                </SquareCard>
                <SquareCard img={data.door.childImageSharp.fluid}
                    title="Door Access Control"/>
                <SquareCard img={data.rfid.childImageSharp.fluid}
                    title="RFID"/>
                <SquareCard img={data.time.childImageSharp.fluid}
                    title="Time and Attendance"/>
                <SquareCard img={data.turnstiles.childImageSharp.fluid}
                    title="Turnstiles"/>
            </Container>
        </Layout>
    )
}