import React from "react"
import Img from "gatsby-image"

import style from "./styles.module.css"

export default ({ title, img, children }) => {
    return (
        <div className={style.cardSquare2}>
            <div style={{
                width: "250px",
            }}>
                <Img fluid={img}></Img>
            </div>
            <div>
                <h3 className="text-center">{title}</h3>
            </div>
            <div className="flex justify-content-center">
                {children}
            </div>
        </div>
    )
}